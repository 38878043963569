/* You can add global styles to this file, and also import other style files */
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

// @import url(./assets/scss/petmetru.scss);
// @import url(./assets/scss/petmetru-responsive.scss);

.mat-slider-thumb,
.mat-slider-track-fill {
  background-color: #000;
}
.mat-radio-inner-circle {
  background-color: #757575;
}

.mat-stepper-vertical {
  font-family: "Roboto Slab", serif;
  .mat-step-label {
    font-size: 27px;
  }

  .mat-step-label-selected {
    font-size: 27px;
    font-weight: 400;
    font-style: normal;
  }

  .mat-radio-button ~ .mat-radio-button {
    margin-top: 0.75rem;
  }

  .mat-slider {
    width: 400px;
  }

  .mat-form-field-infix {
    width: 400px;
  }

  .mat-step-icon {
    color: #000;
    background: none;
    border-radius: 50%;
    height: 36px;
    width: 36px;
  }

  .mat-stepper-vertical-line::before {
    left: 10px;
  }

  .mat-vertical-content {
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .mat-step-header {
    .mat-step-icon {
      color: #000;
      background-color: #fff;
      height: 50px;
      width: 50px;
      .mat-icon {
        font-size: 24px;
        height: 24px;
        width: 24px;
      }
    }
    &.mat-step-icon-selected,
    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      background-color: #fff;
      color: #000;
    }
  }

  .recap-data {
    margin-bottom: 2rem;
    h3 {
      margin-bottom: 1rem;
    }

    p {
      padding-bottom: 0.75rem;
    }

    .personal-info-link {
      color: #000;
      &:hover,
      &:focus {
        color: #000;
      }
    }
  }

  .personal-info-consent {
    margin-bottom: 2rem;
  }

  .mat-checkbox-checked .mat-checkbox-background {
    background-color: #000;
  }
}
