@import '~@angular/material/theming';


// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$patmetru-app-primary: mat-palette($mat-grey, A900, A900, A900);
$patmetru-app-accent:  mat-palette($mat-grey, A900, A900, A900);

// The warn palette is optional (defaults to red).
$patmetru-app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$patmetru-app-theme: mat-light-theme($patmetru-app-primary, $patmetru-app-accent, $patmetru-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
body {
  font-family: 'Roboto Slab', sans-serif;
}

.mat-stepper-vertical {
  font-family: 'Roboto Slab',sans-serif;
}


@include angular-material-theme($patmetru-app-theme);

// TODO: stepper theme overrride